<template>
  <page title="我的推广码" class="text-center" bg-color="white">
    <div class="font-medium p-4 mt-4">商城名称</div>
    <div class="flex flex-col items-center">
      <media :src="TestData.avatar" avatarSize="48" />
      <media src="https://img.alicdn.com/imgextra/i4/O1CN01uhavt01drPL7pKebF_!!6000000003789-2-tps-430-430.png" width="200" ratio="1/1" class="mt-4" />
    </div>
    <div class="mt-3">推广码：9527</div>
    <div class="px-4 mt-3 text-xs leading-6 text-gray-light">扫描二维码，下载软件，使用推广码注册，即可获得优惠</div>
  </page>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  data(){
    return{
      
    }
  },
  computed: {
    ...mapGetters([
      'TestData'
    ])
  },
  methods:{},
  created(){}
}
</script>

<style lang='stylus'>

</style>